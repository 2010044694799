import {Link} from 'gatsby'
import React from 'react'

import Container from '../components/container'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <Container>
      <h1>Page not found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Link to='/projects'>Explore projects instead?</Link>
    </Container>
  </Layout>
)

export default NotFoundPage
